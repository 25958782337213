
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import '@/components/image-picker/image-picker.scss';
import { mapState, mapActions } from 'pinia';
import axios, { AxiosError } from 'axios';
import PickerLibrary from '@/components/image-picker/picker-library.vue';
import ImagePicker from '@/components/image-picker/image-picker.vue';
import PickerUrl from '@/components/image-picker/picker-url.vue';
import { User } from '@/stores/types';
import { Image } from '@/components/image-picker/types';
import { useSettingsStore, useScrollStore } from '@/stores/index.js';
import { formsUrl, formsImageStorageUrl, formsApiEndpoint } from '@/utils/url-manager.js';

@Component({
  computed: {
    ...mapState(useSettingsStore, ['getAuth', 'user']),
  },
  methods: {
    ...mapActions(useScrollStore, ['enableScroll', 'disableScroll']),
  },
  components: {
    ImagePicker: () => import('@/components/image-picker/image-picker.vue'),
    PickerLibrary: () => import('@/components/image-picker/picker-library.vue'),
    PickerUpload: () => import('@/components/image-picker/picker-upload.vue'),
    PickerUrl: () => import('@/components/image-picker/picker-url.vue'),
  },
})
export default class PagesImagePicker extends Vue {
  @Prop({ required: true })
  private readonly name: string;

  @Prop({ required: false, default: 'Image picker' })
  private readonly title: string;

  @Prop({ required: false, default: false })
  private readonly showGallery: boolean;

  @Prop({ required: false, default: null })
  private readonly value: string | null;

  @Ref()
  private readonly imagesRef: PickerLibrary;

  @Ref()
  private readonly pickerRef: ImagePicker;

  @Ref()
  private readonly galleryRef: PickerLibrary;

  @Ref()
  private readonly urlRef: PickerUrl;

  user: User;

  getAuth: boolean;

  enableScroll: (task: string) => void;

  disableScroll: (task: string) => void;

  private pickerUploadSettings = {
    buttonCustomClass: 'button is-primary',
    uploadUrl: `${formsApiEndpoint}images/upload`,
    defaultHeaders: this.getAuthHeader,
    uploadSuccessHandler: this.uploadedHandler,
    uploadErrorHandler: this.uploadErrorHandler,
    multiple: false,
  };

  private pickerLibrarySettings = {
    pageSize: 12,
    getHandler: this.getImagesHandler,
    multiple: false,
    onImageSelected: this.updateSelected,
  };

  private pickerUrlSettings = {
    buttonCustomClass: 'button is-primary',
    inputCustomClass: 'input',
    strings: {
      placeholder: 'Enter image URL',
    },
  };

  private pickerGallerySettings = {
    pageSize: 12,
    getHandler: this.getGalleryHandler,
    multiple: false,
    onImageSelected: this.updateSelected,
  };

  private selected: string | null = null;

  private url: string | null = null;

  $nprogress: any;

  private getAuthHeader() {
    const authHeader = !this.user?.expired
      ? [this.user.token_type, this.user.access_token].join(' ')
      : null;
    return authHeader ? { Authorization: authHeader } : {};
  }

  private async getImagesHandler(offset: number, count: number) {
    if (!this.getAuth) {
      throw new Error('User unauthorized.');
    }

    try {
      const response = await axios.get(
        `${formsApiEndpoint}images?offset=${offset}&count=${count}`
      );
      this.$nprogress.done();
      return response.data;
    } catch (error) {
      this.$nprogress.done();
      throw new Error((error as AxiosError).message);
    }
  }

  private async getGalleryHandler(offset: number, count: number) {
    if (!this.getAuth) {
      throw new Error('User unauthorized.');
    }

    try {
      const response = await axios.get(
        `${formsApiEndpoint}pages/background-images?offset=${offset}&count=${count}`
      );
      this.$nprogress.done();
      return response.data;
    } catch (error) {
      this.$nprogress.done();
      throw new Error((error as AxiosError).message);
    }
  }

  private updateSelected(image: Image) {
    if (image) {
      this.selected = image.downloadUrl;
    } else {
      this.selected = null;
    }
  }

  private uploadedHandler(image: Image) {
    this.imagesRef.unshift(image);
    this.imagesRef.select(image);
    this.pickerRef.switch('images');
  }

  private async uploadErrorHandler(file: File, idx: string, status: boolean, response: string) {
    throw new Error(response);
  }

  private apply() {
    this.$emit('change', this.selected);
    this.disableScroll('PagesImagePicker');
  }

  private close() {
    this.$emit('close');
    this.disableScroll('PagesImagePicker');
  }

  private async opened() {
    this.enableScroll('PagesImagePicker');
    await this.$nextTick();

    if (this.value) {
      const filename = this.value.split('/').pop();
      const image = {
        id: filename,
        name: filename,
        downloadUrl: this.value,
      };

      if (this.value.startsWith(`${formsImageStorageUrl}`)) {
        this.imagesRef.select(image);
        this.pickerRef.switch('images');
      } else if (
        this.showGallery &&
        this.value.startsWith(`${formsUrl}images/background`)
      ) {
        this.galleryRef.select(image);
        this.pickerRef.switch('gallery');
      } else {
        this.urlRef.setUrl(this.value);
        this.pickerRef.switch('byurl');
      }
    }
  }

  mounted() {
    this.$root.$el.appendChild(this.$el);
  }
}
